import React from 'react';
import { Label } from '@naf/label';
import { Text, TextVariant } from '@naf/text';
import styled, { css } from 'styled-components';
import { breakpoints, radius, spacing, themeLight } from '@naf/theme';
import { Button } from '@naf/button';
import { ArrowBack, ArrowForward } from '@styled-icons/material';
import { CarouselCardType } from '../../../../../../../types/CardCarouselBlockType';
import { getLabelVariant } from '../../../../../lib/getLabelVariant';
import BlockContent from '../../../../block-content/BlockContent';
import ResponsiveImage from '../../../../image/ResponsiveImage';
import { ColorVariant } from '../../../../../../../types/featuredBenefitBlockType';

const CardWrapper = styled.div<{ $hasImage?: boolean }>`
  height: 100%;
  box-sizing: border-box;
  padding: ${spacing.space48};
  border: 1px solid ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
  border-radius: ${radius.m};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto ${spacing.space48};
  gap: ${spacing.space16} ${spacing.space48};

  ${({ $hasImage }) =>
    $hasImage
      ? css`
          grid-template-areas:
            'textContent image'
            'navButtons image';
        `
      : css`
          grid-template-areas:
            'textContent textContent'
            'navButtons.';
        `};
  @media (max-width: ${breakpoints.m}) {
    gap: ${spacing.space12} 0;
    padding: ${spacing.space16};
    grid-template-columns: auto;

    ${({ $hasImage }) =>
      $hasImage
        ? css`
            grid-template-rows: repeat(2, auto) ${spacing.space64};
            grid-template-areas:
              'image'
              'textContent'
              'navButtons';
          `
        : css`
            grid-template-rows: auto ${spacing.space64};
            grid-template-areas:
              'textContent'
              'navButtons';
          `}
  }
`;

const BlockContentWrapper = styled.div`
  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
`;

const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: textContent;
  gap: ${spacing.space8};

  @media (max-width: ${breakpoints.m}) {
    h2 {
      margin: 0;
    }
  }
`;

const NavigationButtonAligner = styled.div`
  grid-area: navButtons;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: ${spacing.space12};

  .back {
    .buttonIcon {
      margin-right: ${spacing.space16};
    }
  }

  .next {
    .buttonIcon {
      margin-left: ${spacing.space16};
    }
  }

  @media (max-width: ${breakpoints.m}) {
    .back {
      .buttonIcon {
        margin-right: 0;
      }
      .buttonText {
        display: none;
      }
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  grid-area: image;

  @media (max-width: ${breakpoints.m}) {
    justify-content: center;
    align-items: center;
  }
`;

const ImageCanvas = styled.div`
  width: 100%;
  padding-top: ${(9 / 16) * 100}%;
  position: relative;

  @media (max-width: ${breakpoints.m}) {
    width: 70%;
    padding-top: ${(9 / 16) * 70}%;
  }

  img {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${radius.s};
  }
`;

export const CarouselCard = ({
  card,
  index,
  listLength,
  handlePrevious,
  handleNext,
}: {
  card: CarouselCardType;
  index: number;
  listLength: number;
  handlePrevious(): void;
  handleNext(): void;
}) => {
  const { body, image, label, title } = card;
  const isFirstCard = index === 0;
  const isLastCard = index === listLength - 1;

  return (
    <CardWrapper $hasImage={!!image}>
      <TextContentWrapper>
        {label && (
          <div>
            <Label variant={getLabelVariant(label.colorVariant || ColorVariant.outline)} text={label.title} />
          </div>
        )}
        <Text variant={TextVariant.Header2}>{title}</Text>
        {body && (
          <BlockContentWrapper>
            <BlockContent value={body} />
          </BlockContentWrapper>
        )}
      </TextContentWrapper>
      {image && (
        <ImageWrapper>
          <ImageCanvas>
            <ResponsiveImage imageId={image.publicId} altText={image.altText || image.caption || ''} />
          </ImageCanvas>
        </ImageWrapper>
      )}
      <NavigationButtonAligner>
        <Button className="back" onClick={handlePrevious} variant="outline" disabled={isFirstCard}>
          <ArrowBack size={16} className="buttonIcon" />
          <span className="buttonText">Forrige</span>
        </Button>
        <Button className="next" onClick={handleNext} variant="signature" disabled={isLastCard}>
          <span className="buttonText">Neste</span>
          <ArrowForward size={16} className="buttonIcon" />
        </Button>
      </NavigationButtonAligner>
    </CardWrapper>
  );
};
