import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { CardCarouselBlockType } from '../../../../../../../types/CardCarouselBlockType';
import { ProgressElement } from './CardCarouselProgressElement';
import { CarouselCard } from './CarouselCard';

export const CardCarouselBlock = ({ block }: { block: CardCarouselBlockType }) => {
  const { carouselCards } = block;
  const hasCards = carouselCards.length > 0;
  const [activeCardIndex, setActiveCardIndex] = useState(0);

  if (!hasCards) return null;

  const handlePrevious = () => {
    if (activeCardIndex === 0) return;
    setActiveCardIndex(activeCardIndex - 1);
  };

  const handleNext = () => {
    if (activeCardIndex === carouselCards.length) return;
    setActiveCardIndex(activeCardIndex + 1);
  };

  return (
    <div>
      <ProgressElement index={activeCardIndex} carouselCards={carouselCards} />
      <CarouselWrapper>
        {carouselCards.map((card, index) => (
          <PositionAligner
            key={card.key}
            $isCurrent={index === activeCardIndex}
            style={{
              transform: `translateX(calc(-${activeCardIndex * 100}% - ${
                activeCardIndex * magicCarouselWrapperGap
              }vw))`,
            }}
          >
            <CarouselCard
              card={card}
              index={index}
              listLength={carouselCards.length}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
            />
          </PositionAligner>
        ))}
      </CarouselWrapper>
    </div>
  );
};

const magicCarouselWrapperGap = 50;

const CarouselWrapper = styled.div`
  margin-top: ${spacing.space12};
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: ${magicCarouselWrapperGap}vw;
`;

const PositionAligner = styled.div<{ $isCurrent: boolean }>`
  flex: 0 0 100%;
  box-sizing: border-box;

  ${({ $isCurrent }) =>
    $isCurrent
      ? css`
          opacity: 1;
          transition: transform 0.5s ease, opacity 0.1s ease-out;
        `
      : css`
          opacity: 0;
          transition: transform 0.5s ease, opacity 0.1s cubic-bezier(0.78, 0, 0.22, 1);
        `}

  @media (max-width: ${breakpoints.m}) {
    ${({ $isCurrent }) =>
      $isCurrent
        ? css`
            opacity: 1;
            transition: transform 0.35s ease, opacity 0.35s ease-out;
          `
        : css`
            opacity: 0;
            transition: transform 0.35s ease, opacity 0.1s cubic-bezier(0.78, 0, 0.22, 1);
          `}
  }
`;
