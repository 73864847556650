import React from 'react';
import styled from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { radius, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { CarouselCardType } from '../../../../../../../types/CardCarouselBlockType';

export const ProgressElement = ({ index, carouselCards }: { index: number; carouselCards: CarouselCardType[] }) => {
  const currentCard = carouselCards[index];
  const displayIndex = index + 1;
  const progressbarLabelSuffix = `(${displayIndex}/${carouselCards.length})`;
  const completedPercent = (displayIndex / carouselCards.length) * 100;
  const completedPercentWidth = Math.min(Math.max(completedPercent, 0), 100);

  return (
    <div>
      <Text variant={TextVariant.SubHeader} tag="span">
        {currentCard.hintText ? `${currentCard.hintText} ${progressbarLabelSuffix}` : progressbarLabelSuffix}
      </Text>
      <ProgressBarContainer>
        <StyledProgressBar width={completedPercentWidth} />
      </ProgressBarContainer>
    </div>
  );
};

const ProgressBarContainer = styled.div`
  margin-top: ${spacing.space16};
  width: 100%;
  height: ${spacing.space12};
  background-color: ${nafColor.primary.dew};
  border-radius: ${radius.l};
  display: flex;
  align-items: center;
`;

const StyledProgressBar = styled.div<{ width: number }>`
  width: ${(props) => props.width}%;
  height: ${spacing.space8};
  background-color: ${nafColor.primary.kart};
  border-radius: ${radius.l};
  margin: 0 2px 0 2px;
  transition: width 0.5s cubic-bezier(0, 0, 0.3, 1.03);
`;
